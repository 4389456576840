import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../data/products.service';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    providers: [ ProductsService ]
})

export class ProductComponent implements OnInit {
    pageId: string;
    product: {};

    focus;
    focus1;

    constructor(private _route: ActivatedRoute, private _products : ProductsService) {
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.pageId = params['id'];
            this.product = this._products.getProductData(this.pageId);
        });
    }
}
