import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-youtube-embed',
    templateUrl: './youtube-embed.component.html'
})

export class YoutubeEmbedComponent {
    embedBaseUrl = 'https://www.youtube.com/embed/';
    @Input() youtubeId : string;
    @Input() width = 560;
    @Input() height = 315;

    constructor() {}
}