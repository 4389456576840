import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../data/services.service';

@Component({
    selector: 'app-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss'],
    providers: [ ServicesService ]
})

export class ServiceComponent implements OnInit {
    pageId: string;
    service: {};

    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;

    constructor(private _route: ActivatedRoute, private _services : ServicesService) {
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.pageId = params['id'];
            this.service = this._services.getServiceContent(this.pageId);
        });
    }
}
