import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../data/products.service';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    providers: [ ProductsService ]
})

export class ProductsComponent implements OnInit {
    products: any[];

    constructor(private _route: ActivatedRoute, private _products : ProductsService) {
    }

    ngOnInit() {
        this.products = this._products.getProductOrder();
    }

    getProductData(productId : string) : {} {
        return this._products.getProductData(productId);
    }
}
