import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ServiceComponent } from './services/service.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './products/product.component';
import { PeopleComponent } from './people/people.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'services',         component: ServicesComponent },
    { path: 'services/:id',     component: ServiceComponent },
    { path: 'products',         component: ProductsComponent },
    { path: 'products/:id',     component: ProductComponent },
    { path: 'people',           component: PeopleComponent },
//    { path: 'profile/:id',      component: ProfileComponent },
//    { path: 'contact',          component: ContactComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
