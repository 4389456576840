import { Injectable, OnInit } from '@angular/core';
//import { ClientRequest } from 'http';

@Injectable()

export class ServicesService implements OnInit {
    contents = {};
    services = {};

    constructor() {
        var clients = [
            {
                'name': 'Agilent Technologies',
                'imgname': 'Agilent_Technologies-Logo.wine.svg',
                'url': 'https://www.agilent.com'
            },{
                'name': 'Bank of New York Mellon',
                'imgname': 'The_Bank_of_New_York_Mellon-Logo.wine.svg',
                'url': 'https://www.bnymellon.com'
            },{
                'name': 'Biogen',
                'imgname': 'Biogen-Logo.wine.svg',
                'url': 'https://www.biogen.com'
            },{
                'name': 'Capital One',
                'imgname': 'Capital_One_logo.svg',
                'url': 'https://www.capitalone.com'
            },{
                'name': 'Cardinal Health',
                'imgname': 'Cardinal_Health-Logo.wine.svg',
                'url': 'https://www.cardinalhealth.com'
            },{
                'name': 'Citigroup',
                'imgname': 'Citigroup-Logo.wine.svg',
                'url': 'https://www.citigroup.com'
            },{
                'name': 'Coupa',
                'imgname': 'Coupa_Logo_4color.svg',
                'url': 'https://www.coupa.com'
            },{
                'name': 'Depository Trust & Clearing Corporation',
                'imgname': 'dtcc.svg',
                'url': 'https://www.dtcc.com'
            },{
                'name': 'Encova (formerly Motorists Mutual)',
                'imgname': 'encova-logo-full-color.png',
                'url': 'https://www.encova.com'
            },{
                'name': 'Gartner',
                'imgname': 'Gartner logo.svg',
                'url': 'https://www.gartner.com'
            },{
                'name': 'HP',
                'imgname': 'HP_Inc.-Logo.wine.svg',
                'url': 'https://www.hp.com'
            },{
                'name': 'J.D. Power & Associates',
                'imgname': 'jdpower.svg',
                'url': 'https://www.jdpower.com'
            },{
                'name': 'JPMorgan Chase',
                'imgname': 'JPMorgan_Chase-Logo.wine.svg',
                'url': 'https://www.jpmorganchase.com'
            },{
                'name': 'Liberty Mutual',
                'imgname': 'Liberty_Mutual-Logo.wine.svg',
                'url': 'https://www.libertymutual.com'
            },{
                'name': 'Nike',
                'imgname': 'Nike,_Inc.-Nike-Logo.wine.svg',
                'url': 'https://www.nike.com'
            },{
                'name': 'New York City MTA',
                'imgname': 'MTA_NYC_logo.svg',
                'url': 'https://mta.info'
            },{
                'name': 'Government of Prince Edward Island, Canada',
                'imgname': 'Flag_of_Prince_Edward_Island.svg',
                'url': 'https://www.princeedwardisland.ca'
            },{
                'name': 'Starr Companies Insurance',
                'imgname': 'Starr_Companies_Logo.png',
                'url': 'https://starrcompanies.com'
            },{
                'name': 'TIAA',
                'imgname': 'Teachers_Insurance_and_Annuity_Association_of_America-Logo.wine.svg',
                'url': 'https://www.tiaa.org'
            },{
                'name': 'United States Department of Labor: Office of the Assistant Secretary for Administration & Management',
                'imgname': 'Seal_of_the_United_States_Department_of_Labor.svg',
                'url': 'https://www.dol.gov/agencies/oasam'
            },{
                'name': 'United States Department of State',
                'imgname': 'Seal_of_the_United_States_Department_of_State.svg',
                'url': 'https://www.state.gov'
            },{
                'name': 'United States Department of the Treasury: Bureau of the Fiscal Service',
                'imgname': 'Seal_of_the_United_States_Department_of_the_Treasury.svg',
                'url': 'https://home.treasury.gov'
            },{
                'name': 'United States Patent & Trademark Office',
                'imgname': 'Seal_of_the_United_States_Patent_and_Trademark_Office.svg',
                'url': 'https://www.uspto.gov'
            },{
                'name': 'Wellington Management Company',
                'imgname': 'Wellington_Management_logo.svg',
                'url': 'https://www.wellington.com'
            }
        ];

        this.contents = {
            'clients': clients,
            'services': [
                'ecm',
                'bpm',
                'iim',
                'alfresco'
            ]
        };

        this.services = {
            'ecm': {
                'title': 'Content Services',
                'shortTitle': 'Content',
                'description': 'Inteligr8 provides professional services to assist you with your Enterprise Content Management (ECM) needs.  This includes the initial assessment, expert recommendations, business requirement analysis, architectural design, infrastructure configuration and deployment, and the delivery of documentation and training.',
                'icon': 'single-copy-04',
                'imgpath': 'storyset/documents-pana-yellow.svg',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Every organization deals with documents, whether it is invoices, receipts, forms, scans, photos, videos, or many others.  These artifacts are content, which are all under the umbrella of Content Management.'
                    },{
                        'title': 'Why?',
                        'text': 'The new economy demands that these content be digitized and managed.  It could be for customer service, searchability, legal retention, and many other great reasons.'
                    },{
                        'title': 'How?',
                        'text': 'Several vendors offer software to help solve this problem.  The software is not the solution.  Inteligr8 is here to provide the solution using modern ECM software to move your organization into the new economy.'
                    }
                ],
                'sections': [{
                    'icon': 'fat-add',
                    'title': 'New Systems',
                    'subtitle': 'Inteligr8 will guide you from inception through deployment, whether you are completely new to Content Management or a seasoned organization.',
                    'imgpath': 'storyset/documents-pana-yellow.svg',
                    'bullets': [
                        {
                            'icon': 'bullet-list-67',
                            'text': 'Gather and analyze business and technical requirements and user stories'
                        },{
                            'icon': 'ruler-pencil',
                            'text': 'Design and architect a complete content solution'
                        },{
                            'icon': 'building',
                            'text': 'Implement and deploy the system to your infrastructure'
                        },{
                            'icon': 'settings',
                            'text': 'Analyze and optimize the system for the best performance'
                        },{
                            'icon': 'books',
                            'text': 'Provide full documentation and routine status updates'
                        }
                    ]
                },{
                    'icon': 'delivery-fast',
                    'title': 'Migrations',
                    'subtitle': 'Inteligr8 will guide you through migrations between existing disparate systems.',
                    'imgpath': 'storyset/cloud-sync-pana-green.svg',
                    'bullets': [
                        {
                            'icon': 'settings',
                            'text': 'Analyze source and target systems and their data'
                        },{
                            'icon': 'bullet-list-67',
                            'text': 'Develop a migration plan, respecting business and technical constraints'
                        },{
                            'icon': 'button-play',
                            'text': 'Execute migrations'
                        },{
                            'icon': 'books',
                            'text': 'Provide full documentation and routine status updates'
                        }
                    ]
                },{
                    'icon': 'badge',
                    'title': 'Consulting',
                    'subtitle': 'Inteligr8 will provide you independent and insightful knowledge and expertise, focusing on the interest of your organization.',
                    'imgpath': 'storyset/recommendation-letter-pana-red.svg',
                    'bullets': [
                        {
                            'icon': 'check-bold',
                            'text': 'Expert knowledge across mutiple content management solutions'
                        },{
                            'icon': 'check-bold',
                            'text': 'Not financially bound to specific vendors or solutions'
                        },{
                            'icon': 'check-bold',
                            'text': 'Compare pricing and capabilities across vendors and infrastructure'
                        },{
                            'icon': 'check-bold',
                            'text': 'Follow best practices to futureproof both your system and business needs'
                        }
                    ]
                }]
            },
            'bpm': {
                'title': 'Business Process Services',
                'shortTitle': 'Process',
                'description': 'Inteligr8 provides professional services to assist you with your Business Process Management (BPM) needs.  This includes the initial assessment, expert recommendations, business requirement analysis, architectural design, infrastructure configuration and deployment, and the delivery of documentation and training.',
                'icon': 'button-play',
                'imgpath': 'storyset/data-points-pana-green.svg',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Every organization has tasks that need to be completed.  These tasks are performed in a sequence, which consititutes a process.  This places all those tasks under the umbrella of Process Management.'
                    },{
                        'title': 'Why?',
                        'text': 'The new economy demands that these processes be digitized and managed.  It could be for customer service, business efficiency, automation, integration, legal, and many other great reasons.'
                    },{
                        'title': 'How?',
                        'text': 'Several vendors offer software to help solve this problem.  The software is not the solution.  Inteligr8 is here to provide the solution using modern BPM software to move your organization into the new economy.'
                    }
                ],
                'sections': [{
                    'icon': 'fat-add',
                    'title': 'New Systems',
                    'subtitle': 'Inteligr8 will guide you from inception through deployment, whether you are completely new to process management or a seasoned organization.',
                    'imgpath': 'storyset/data-points-pana-yellow.svg',
                    'bullets': [
                        {
                            'icon': 'bullet-list-67',
                            'text': 'Gather and analyze business and technical requirements and user stories'
                        },{
                            'icon': 'ruler-pencil',
                            'text': 'Design and architect a complete business process solution'
                        },{
                            'icon': 'building',
                            'text': 'Implement and deploy the system to your infrastructure'
                        },{
                            'icon': 'settings',
                            'text': 'Analyze and optimize the system for the best performance'
                        },{
                            'icon': 'books',
                            'text': 'Provide full documentation and routine status updates'
                        }
                    ]
                },{
                    'icon': 'delivery-fast',
                    'title': 'Migrations',
                    'subtitle': 'Inteligr8 will guide you through migrations between existing disparate systems.',
                    'imgpath': 'storyset/cloud-sync-pana-green.svg',
                    'bullets': [
                        {
                            'icon': 'settings',
                            'text': 'Analyze source and target systems and their data'
                        },{
                            'icon': 'bullet-list-67',
                            'text': 'Develop a migration plan, respecting business and technical constraints'
                        },{
                            'icon': 'button-play',
                            'text': 'Execute migrations'
                        },{
                            'icon': 'books',
                            'text': 'Provide full documentation and routine status updates'
                        }
                    ]
                },{
                    'icon': 'badge',
                    'title': 'Consulting',
                    'subtitle': 'Inteligr8 will provide you independent and insightful knowledge and expertise, focusing on the interest of your organization.',
                    'imgpath': 'storyset/recommendation-letter-pana-red.svg',
                    'bullets': [
                        {
                            'icon': 'check-bold',
                            'text': 'Expert knowledge across mutiple process management solutions'
                        },{
                            'icon': 'check-bold',
                            'text': 'Not financially bound to specific vendors or solutions'
                        },{
                            'icon': 'check-bold',
                            'text': 'Compare pricing and capabilities across vendors and infrastructure'
                        },{
                            'icon': 'check-bold',
                            'text': 'Follow best practices to futureproof both your system and business needs'
                        }
                    ]
                }]
            },
            'iim': {
                'title': 'Intelligence Services',
                'shortTitle': 'Intelligence',
                'description': 'Inteligr8 provides professional services to assist you with your Intelligent Information Management (IIM) needs.  This includes everything described for ECM and BPM above, integration of those technologies, and integration with machine learning and artificial intelligence (AI) technologies.',
                'icon': 'bulb-61',
                'imgpath': 'storyset/processing-pana-red.svg',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Most organizations have people that perform mundane and repetitive tasks.  Some organizations have little but valuable insight into their data.  All of these problems can be solved with Process Management, Machine Learning, and Artificial Intelligence.'
                    },{
                        'title': 'Why?',
                        'text': 'Smart and intelligent processing of tasks and data is how many businesses are improving operational efficiency.'
                    },{
                        'title': 'How?',
                        'text': 'Several vendors offer software to help solve this problem.  The software is just a framework; not a solution.  Inteligr8 is here to provide the solution using modern software to move your organization into the new economy.'
                    }
                ],
                'sections': [{
                    'icon': 'atom',
                    'title': 'Integration',
                    'subtitle': 'Inteligr8 will integrate content and process into one a single powerful solution, so your people can work smarter.',
                    'imgpath': 'storyset/processing-pana-yellow.svg',
                    'bullets': [
                        {
                            'icon': 'building',
                            'text': 'Custom modules and extensions for backend integrations'
                        },{
                            'icon': 'building',
                            'text': 'Custom services and interfaces for frontend integrations'
                        },{
                            'icon': 'app',
                            'text': 'Evaluation and deployment of 3rd party products for specific integrations'
                        }
                    ]
                },{
                    'icon': 'bulb-61',
                    'title': 'Machine Learning',
                    'subtitle': 'Inteligr8 will integrate powerful machine learning services into your Content and Process Management systems.',
                    'imgpath': 'storyset/artificial-intelligence-pana-green.svg',
                    'bullets': [
                        {
                            'icon': 'cart',
                            'text': 'Integration with AWS Machine Learning services for text, image, and video recognition and meta-data extraction.'
                        },{
                            'icon': 'single-copy-04',
                            'text': 'Integration with various scanning and optical character recognition technologies.'
                        },{
                            'icon': 'cart',
                            'text': 'Integration with Google Language AI services for content translations.'
                        }
                    ]
                }]
            },
            'alfresco': {
                'title': 'Services for Alfresco Products',
                'shortTitle': 'Alfresco',
                'description': 'Inteligr8 is an expert provider of services for Alfresco products.  The people of Inteligr8 are certified in all Alfresco products and are recognized experts by Fortune 500 companies and by other Alfresco experts.',
                'icon': 'app',
                'imgpath': 'storyset/experts-pana-blue.svg',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Alfresco Software products target organizations in need of Content and Process Management solutions.  The software, Hyland, and their partners provide several modules and extensions to leverage the platform, making it invaluable in a wide variety of scenarios.'
                    },{
                        'title': 'Why?',
                        'text': 'The core Alfresco Software products are open source.  It rests on top of open standards and other open source products.  This has led to a mature community of modules, extensions, services, and interfaces.  All of this is backed by Alfresco Support to make sure everything runs smoothly.'
                    },{
                        'title': 'How?',
                        'text': 'Several Hyland Partners offer solutions built on Alfresco Software.  Inteligr8 is independent and represents only you; not both you and the software vendor.  Inteligr8 is led by a fully certified expert in all Alfresco Software applications and roles.  You can trust that Inteligr8 knows how to deliver Alfresco-based solutions.'
                    }
                ],
                'externalLinks': [
                    {
                        'title': 'Discover Alfresco Software',
                        'url': 'https://www.alfresco.com'
                    },{
                        'title': 'Discover Hyland Software',
                        'url': 'https://www.hyland.com'
                    }
                ],
                'videos': [
                    {
                        'title': 'Alfresco Replication',
                        'subtitle': 'Presentation by Rich McKnight of Alfresco and Brian Long of Inteligr8 (of Alfresco at the time) at Alfresco Summit in London.',
                        'youtubeId': 'Cnu_XeEbBBM'
                    },{
                        'title': 'Content Encryption Methods',
                        'subtitle': 'Presentation by Brian Long of Inteligr8 (of Alfresco at the time) at Alfresco Summit in London.',
                        'youtubeId': 'YvvLm3STxDk'
                    }
                ],
                'clients': clients,
                'sections': [{
                    'icon': 'ungroup',
                    'title': 'Components',
                    'subtitle': 'Inteligr8 is experienced in installing, configuring, and deploying ...',
                    'imgpath': 'storyset/product-teardown-pana-yellow.svg',
                    'bullets': [
                        {
                            'icon': 'app',
                            'text': 'Alfresco Content Services'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Process Services'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Governance Services'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Transform Service & Engines'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Search & Insight Services'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Intelligence Services'
                        },{
                            'icon': 'app',
                            'text': 'Alfresco Identity Services'
                        }
                    ]
                },{
                    'icon': 'ungroup',
                    'title': 'Infrastructures',
                    'subtitle': 'Inteligr8 is able to install, configure, and deploy using ...',
                    'imgpath': 'storyset/server-pana-green.svg',
                    'bullets': [
                        {
                            'icon': 'cloud-upload-96',
                            'text': 'Amazon Web Services CloudFormation for AWS'
                        },{
                            'icon': 'cloud-upload-96',
                            'text': 'Hashicorp Terraform to most cloud and container providers'
                        },{
                            'icon': 'cloud-upload-96',
                            'text': 'Helm to most container providers'
                        },{
                            'icon': 'box-2',
                            'text': 'Docker Compose for development and proof of concept environments'
                        },{
                            'icon': 'box-2',
                            'text': 'Manual installations using any flavor of Linux or Microsoft Windows'
                        }
                    ]
                },{
                    'icon': 'atom',
                    'title': 'Advanced Topics',
                    'subtitle': 'Inteligr8 is a foremost expert on ...',
                    'imgpath': 'storyset/experts-pana-red.svg',
                    'bullets': [
                        {
                            'icon': 'key-25',
                            'text': 'Advanced storage configuration, encryption, and cloud storage'
                        },{
                            'icon': 'settings',
                            'text': 'Non-standard installation and configuration tailored to your technical requirements'
                        },{
                            'icon': 'world',
                            'text': 'Global data replication and node distribution'
                        },{
                            'icon': 'support-16',
                            'text': 'System health checks, analysis, performance tuning, and futureproofing'
                        }
                    ]
                }]
            }
        };
    }

    ngOnInit() {}

    getServiceContent(serviceId : string) : {} {
        return this.services[serviceId];
    }

    getServicesContent() : {} {
        return this.contents;
    }

}
