import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PeopleService } from '../data/people.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [ PeopleService ]
})

export class ProfileComponent implements OnInit {
    personId: String;
    person: {};

    constructor(private peopleService: PeopleService, private _route: ActivatedRoute) {
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.personId = params['id'];
            this.person = this.peopleService.getPerson(this.personId);
        });
    }

}
