import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../data/services.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
    providers: [ ServicesService ]
})

export class ServicesComponent implements OnInit {
    content: {};

    constructor(private _route: ActivatedRoute, private _services : ServicesService) {
    }

    ngOnInit() {
        this.content = this._services.getServicesContent();
    }

    getServiceContent(serviceId : string) : {} {
        return this._services.getServiceContent(serviceId);
    }
}
