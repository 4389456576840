import { Injectable, OnInit } from '@angular/core';
//import { ClientRequest } from 'http';

@Injectable()

export class ProductsService implements OnInit {
    productOrder = [];
    data = {};

    constructor() {
        this.productOrder = [
            'ocr',
            'acs-foldering',
            'propagate-branches'
        ];

        this.data = {
            'ocr': {
                'title': 'Inteligr8 OCR',
                'shortTitle': 'OCR',
                'brief': 'OCR tools for both tight Alfresco integration and command line execution.',
                'description': 'Inteligr8 OCR provides an integrated optical character recognition solution for Alfresco Content Services or a simple command line.',
                'icon': 'app',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Most organizations deal with scanned text-based documents, whether it is invoices, receipts, forms, or many others.'
                    },{
                        'title': 'Why?',
                        'text': 'A scanned document is not searchable, making it highly unlikely those documents will serve any useful business purpose.  Optical character recognition can read those documents, which allows them to be searched, and they can then be put to productive use for the business.'
                    },{
                        'title': 'How?',
                        'text': 'Several vendors offer software to help solve this problem.  Inteligr8 provides a flexible and highly scalable solution that can be applied before, during, or after migrations.'
                    }
                ],
                'features': [
                    'Supports PDF and TIFF source documents',
                    'Supports TXT, HOCR, and PDF target documents',
                    'Auto-detects DPI and page orientation',
                    'Automatically reduces scanner "noise" for optimal character recognition',
                    'Configure as an Alfresco Transform Engine',
                    'Configure as a command line interface tool',
                    'Includes a Alfresco Platform module for tight integration with Alfresco Content Services'
                ],
                'screenshots': [
                    {
                        'title': 'Example Text Extraction',
                        'caption': 'Original Scanned Document',
                        'imgpath': 'screenshots/screenshot-pdf.png',
                        'caption2': 'Raw Text after OCR',
                        'imgpath2': 'screenshots/screenshot-txt.png'
                    },
                    {
                        'title': 'Example PDF Result',
                        'caption': 'Selecting OCR\'d text in the PDF',
                        'imgpath': 'screenshots/screenshot-pdf-select.png',
                        'description': {
                            'title': 'Text Overlay',
                            'text': 'An OCR\'d PDF has text overlayed where the characters were recognized.  You can select and copy the text as you would with any text-based PDF.'
                        }
                    }
                ]
            },
            'acs-foldering': {
                'title': 'Inteligr8 Foldering',
                'shortTitle': 'Foldering ACS Platform Module',
                'brief': 'Alfresco Content Service extension for node autofiling.',
                'description': 'Inteligr8 Foldering provides property-based configuration for Alfresco node locators, folder hashing, and autofiling.',
                'icon': 'app',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Most Alfresco Content Services implementations store large data sets.'
                    },{
                        'title': 'Why?',
                        'text': 'Best practices for foldering in Alfresco Content Services require sprawling folder structures for large data sets.'
                    },{
                        'title': 'How?',
                        'text': 'Instead of using ACS Rules or your own custom model, Intelgr8 Foldering provides a simple, highly configurable drop-in module to get the job done.'
                    }
                ],
                'features': [
                    'Supports property-based configurable custom node locators',
                    'Supports highly configurable metadata-based folder structure hashing and filing',
                    'Filing based on any node date, text, or numeric property with highly configurable path/hash',
                    'Autofiling based on path, node types, aspects, and properties',
                    'Trigger filing via node events, REST services, and/or ACS actions',
                    'Transaction-safe asynchronous autofiling for high throughput systems',
                    'Supports folder filing in conjunction with Alfresco Governance Services (RM)',
                ]
            },
            'propagate-branches': {
                'title': 'Inteligr8 Propagator',
                'shortTitle': 'Propagator',
                'brief': 'Git branch propagation tools to automate configuration propagation.',
                'description': 'Inteligr8 Propagator provides a Git-based configuration management solution for those implementing infrastructure as code (IaC).  The tool automates the propagation of changes to derivative configurations.',
                'icon': 'app',
                'leads': [
                    {
                        'title': 'What?',
                        'text': 'Most organizations deal with multiple environments, tools, or templates with very similar configurations or contents.'
                    },{
                        'title': 'Why?',
                        'text': 'All too often environment configurations and other derivative text are managed separately and get out of sync.  This leads to missed updates causing production problems and even bigger debugging issues.  It is also very time consuming to manually synchronize derivative works.'
                    },{
                        'title': 'How?',
                        'text': 'Git is an excellent tool for managing derivative texts using its branches and merging capability.  It lacks the automatic propagation capability.  This tool fills that gap with command line tools and webhooks for GitHub and BitBucket.'
                    }
                ],
                'features': [
                    'Supports any cloneable Git Repository with the command line tool',
                    'Supports GitHub and BitBucket Git Repositories with webhooks and pull requests',
                    'Great for managing environment configurations: DEV to TEST to UAT to PROD; and other derivative environments',
                    'Great for managing client environment configurations for SaaS/PaaS offerings',
                    'Great for managing training environments',
                    'Great for managing documentation that is active for multiple versions of the same product'
                ]
            }
        };
    }

    ngOnInit() {}

    getProductData(productId : string) : {} {
        return this.data[productId];
    }

    getProductOrder() : any[] {
        return this.productOrder;
    }

}
