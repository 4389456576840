import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;

    contents = [];

    constructor() {
        this.contents = [
            {
                'icon': 'circle-08',
                'title': 'Services',
                'subtitle': 'Inteligr8 provides professional services for your enterprise needs.',
                'imgpath': 'storyset/developer-activity-pana-red.svg',
                'bullets': [
                    {
                        'icon': 'bold-right',
                        'text': 'Content, Process, Search, and Machine Learning'
                    },{
                        'icon': 'bold-right',
                        'text': 'Expert and Experienced Consulting'
                    }
                ]
            },{
                'icon': 'app',
                'title': 'Products',
                'subtitle': 'Inteligr8 provides applications, scripts, and tools for your enterprise needs.',
                'imgpath': 'storyset/programmer-pana-blue.svg',
                'bullets': [
                    {
                        'icon': 'bold-right',
                        'text': 'Modules and Extensions for Alfresco Products'
                    },{
                        'icon': 'bold-right',
                        'text': 'Tools and Webhooks for Git'
                    }
                ]
            }
        ]
    }

    ngOnInit() {}
}
