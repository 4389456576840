import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { ProductsService } from '../../data/products.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [ ProductsService ]
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    services : any[];
    products : any[];

    constructor(public location: Location, private router: Router, private _products: ProductsService) {
        this.services = [
            {
                'icon': 'single-copy-04',
                'title': 'Content',
                'text': 'Learn about our Enterprise Content Management offerings.',
                'routerLink': ['/services', 'ecm']
            },{
                'icon': 'button-play',
                'title': 'Process',
                'text': 'Learn about our Business Process Management offerings.',
                'routerLink': ['/services', 'bpm']
            },{
                'icon': 'bulb-61',
                'title': 'Intelligence',
                'text': 'Learn about our Information Intelligence Management offerings.',
                'routerLink': ['/services', 'iim']
            },{
                'icon': 'app',
                'title': 'Alfresco',
                'text': 'Learn about our Alfresco Product service offerings.',
                'routerLink': ['/services', 'alfresco']
            }
        ];
    }

    ngOnInit() {
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
        } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
        }
      });
      this.location.subscribe((ev:PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
      });

      this.products = this._products.getProductOrder();
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    getProductData(productId : string) : {} {
        return this._products.getProductData(productId);
    }
}
