import { Injectable, OnInit } from '@angular/core';

@Injectable()

export class PeopleService implements OnInit {
    people = [];

    constructor() {
        this.people.push({
            'id': 'brian.long',
            'name': 'Brian M. Long',
            'title': 'Founder',
            'imgname': 'profile-brian-greenery-512.jpg',
            'role': 'Content & Process Management Expert',
            'email': 'brian@inteligr8.com',
            'location': 'Northern New Jersey, United States',
            'yearsExperience': (new Date().getFullYear() - 1998),
            'certifications': [
                'Alfresco Content Services Certified Administrator',
                'Alfresco Content Services Certified Engineer',
                'Alfresco Process Services Certified Administrator',
                'Alfresco Process Services Certified Engineer',
                'Sun Certified Java Programmer',
                'Sun Certified Business Component Developer',
                'Sun Certified Web Component Developer',
            ],
            'linkedinUrlId': 'brian-m-long',
            'twitterHandle': '@brianmlong'
        });
    }

    ngOnInit() {}

    getPerson(personId : String) : {} {
        return this.people.find((person)=>{
            return person.id == personId;
        });
    }

}
