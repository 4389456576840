import { Component, OnInit } from '@angular/core';
import { PeopleService } from '../data/people.service';

@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.scss'],
    providers: [ PeopleService ]
})

export class PeopleComponent implements OnInit {

    people : object[] = [];

    constructor(private peopleService : PeopleService) {
        this.people = this.peopleService.people;
    }
    
    ngOnInit() {
    }

}
